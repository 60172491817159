import { PageHead } from "@/components/common";
import { MainLayout } from "@/components/layout";

const Page404 = () => {
  return (
    <>
      <PageHead title="404" />
      <MainLayout>page 404</MainLayout>
    </>
  );
};

export default Page404;
